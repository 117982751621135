import React from 'react'
import tw,{styled} from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from 'src/ui-treact/components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from "src/ui-treact/components/misc/Buttons.js";
import store from 'src/stores/common-store';

const Container = tw.div`flex flex-col items-center justify-between w-full pt-8`;
const TwoColumn = tw.div`flex flex-col items-start justify-between md:flex-row max-w-screen-lg mx-auto py-2`;
const RightColumn = tw.div`flex flex-col md:w-5/12 max-w-screen-xl mx-auto py-8 md:py-16 items-center p-4`;
const LeftColumn = tw.div`flex flex-col md:w-5/12 items-start justify-start max-w-screen-xl mx-auto py-8 md:py-16 p-4`;
const TextContent = tw.div`text-center md:text-center`;

const Subheading = tw(SubheadingBase)`text-center text-black`;
const Heading = tw(SectionHeading)`mt-0 font-black text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const HeadingLeftCard = tw(
    SectionHeading
  )`mt-4 font-black text-center text-sm lg:text-xl leading-tight text-black p-4`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-0 text-sm inline-block mx-auto md:mx-0 rounded-full bg-secondary-900 hover:no-underline w-48`;


export default function TwoColumnMultipleImages({data, page}) {
    let finalData= data?.schema_json;  
    let allLefties = finalData.cards.map((card)=>{
        return(
            <div key={card.heading}>
                <HeadingLeftCard>{card.heading}</HeadingLeftCard>
                <img src={card.img_src} alt="" />
            </div>
        )
    })
    return (
        <Container>
            <TextContent>
                {finalData.sub_heading && <Subheading>{finalData.sub_heading}</Subheading>}
                <Heading dangerouslySetInnerHTML={{ __html: finalData.heading }}></Heading>
            </TextContent>
            <TwoColumn>
                <LeftColumn>
                    {allLefties}
                </LeftColumn>

                <RightColumn>
                    <img src={finalData.img_src} alt="" />
                </RightColumn>
            </TwoColumn>
            <PrimaryButton 
            onClick={(e)=>{
                e.preventDefault();
                store.showCart();
            }}
            >
                Open Cart
            </PrimaryButton>
        </Container>
    )
}